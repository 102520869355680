import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboardTeacher, faChartBar, faMobileAlt, faNetworkWired,faCheck,faCheckCircle, faQuoteLeft, faQuoteRight, faStore, faUserTag } from '@fortawesome/free-solid-svg-icons'
import valerian from "../images/valerian-1.svg"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <Hero />
    <Seo title="Home"
         description="Stress Free IT"
    pathname={"/"}/>
      <div className="container">
          <div className="container my-5">
              <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                  <div className="col-lg-12 p-3 p-lg-5 pt-lg-3">
                      <h1 className="display-4 fw-bold lh-1 text-body-emphasis">Stress-free IT.</h1>
                      <p className={"lead"}>New Vertical Technologies provides clients with a tailored package of
                        services. After meeting with you to discuss your needs, our staff will build a
                        personalized plan to fulfill those needs and make your life easier.</p>

                      <p className={"lead"}>We want you to focus on your business. As your IT services provider, we will
                          ensure that your technology infrastructure works with your business, rather than against. Our
                          goal is to keep your mind on what's important by relieving any concerns about technology.
                      </p>
                      <p className={"lead"}>At New Vertical Technologies, customer service is very important to us. We
                          make it a top priority. Our clients experience extremely responsive technical support from our
                          staff.
                      </p>

                      <p className={"lead"}>New Vertical Technologies provides clients with support, security, and
                      personalized IT services to satisfy their specific needs.</p>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                          <a href={"/contact/main/"} className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Contact Us</a>
                          <a href={"mailto:info@newverticaltech.com"} className="btn btn-outline-secondary btn-lg px-4">Email</a>
                      </div>
                  </div>

              </div>
          </div>

          <div className="home-testimonials test-center">
              <h2>Our Testimonials</h2>
              <div className="table">
                  <div className="testi">
                      <p>
                          <span style={{width:'20px'}}><FontAwesomeIcon icon={faQuoteLeft} size="sm"/></span>
                            Their customer support is quick to respond and always helpful.
                          <span style={{width:'20px'}}><FontAwesomeIcon icon={faQuoteRight} size="sm"/></span>
                          <br /><br />- SARA | EASTERN SHORE DENTAL</p>
                  </div>
                  <div className="testi">
                      <p>
                          <span style={{width:'20px'}}><FontAwesomeIcon icon={faQuoteLeft} size="sm"/></span>
                            New Vertical Tech solved all of my IT problems!
                          <span style={{width:'20px'}}><FontAwesomeIcon icon={faQuoteRight} size="sm"/></span>
                          <br /><br />- CHRIS | SHORE ACCOUNTING
                      </p>
                  </div>
                  <div className="testi">
                      <p>
                          <span style={{width:'20px'}}><FontAwesomeIcon icon={faQuoteLeft} size="sm"/></span>
                            Their services made our day-to-day so much easier. Thank you NVT!
                          <span style={{width:'20px'}}><FontAwesomeIcon icon={faQuoteRight} size="sm"/></span>
                          <br /><br />- TRACY | GMC CONTRACTORS
                      </p>
                  </div>
              </div>
          </div>

          <p>*All prices are per computer and generally projects are separate work statements.</p>
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">Basic</h4>
                </div>
                <div className="card-body">

                  <ul className="list-unstyled mt-3 mb-4">
                    <li>Up to 10 computers</li>
                    <li>Full monitoring and maintenance</li>
                    <li>Update Support</li>
                    <li>Help center access</li>
                    <li>(Added Support $150 / Hour)</li>
                  </ul>
                  <a href={"/contact/main/"} className="w-100 btn btn-outline-primary btn-lg px-4 me-md-2 fw-bold">Sign up today</a>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">Priority</h4>
                </div>
                <div className="card-body">

                  <ul className="list-unstyled mt-3 mb-4">
                      <li>10 - 50 computers</li>
                    <li>Basic package</li>
                      <li>+</li>
                    <li>Priority Queue</li>
                    <li>On Site Service Available</li>
                    <li>(Added Support $125 / hour)</li>
                  </ul>
                  <a href={"/contact/main/"} className="w-100 btn btn-primary btn-lg px-4 me-md-2 fw-bold">Get started</a>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm border-primary">
                <div className="card-header py-3 text-white bg-primary border-primary">
                  <h4 className="my-0 fw-normal">Pro</h4>
                </div>
                <div className="card-body">

                  <ul className="list-unstyled mt-3 mb-4">
                      <li>50+ computers</li>
                    <li>Priority package</li>
                      <li>+</li>
                      <li>Email Support</li>
                    <li>Unlimited Support Tickets</li>
                    <li>Customized Support Helpdesk</li>
                    <li>Advanced Network Protection</li>
                    <li>Proactive Security</li>

                  </ul>
                  <a href={"/contact/main/"} className="w-100 btn btn-primary btn-lg px-4 me-md-2 fw-bold">Contact us</a>
                </div>
              </div>
            </div>
          </div>

          <h2 className="display-6 text-center mb-4">Compare plans</h2>

          <div className="table-responsive">
            <table className="table text-center">
              <thead>
              <tr>
                <th style={{width: "34%"}}></th>
                <th style={{width: "22%"}}>Basic</th>
                <th style={{width: "22%"}}>Priority</th>
                <th style={{width: "22%"}}>Pro</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row" className="text-start">Monitoring and Maintenance</th>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
              </tr>
              <tr>
                <th scope="row" className="text-start">Active Threat Protection</th>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
              </tr>

              <tr>
                <th scope="row" className="text-start">Update Management</th>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
              </tr>
              <tr>
                <th scope="row" className="text-start">Help Center Access</th>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
              </tr>
              <tr>
                <th scope="row" className="text-start">Server Management ($75) / Month</th>
                <td></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
              </tr>
              <tr>
                <th scope="row" className="text-start">Priority Queue</th>
                <td></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
              </tr>
              <tr>
                  <th scope="row" className="text-start">Email and Identity Protection</th>
                  <td></td>
                  <td className={"text-success"}></td>
                  <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
              </tr>
              <tr>
                <th scope="row" className="text-start">Unlimited Tickets Included</th>
                <td></td>
                <td></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
              </tr>

              <tr>
                <th scope="row" className="text-start">Advanced Network Protection</th>
                <td></td>
                <td></td>
                <td className={"text-success"}><FontAwesomeIcon icon={faCheckCircle}/></td>
              </tr>

              </tbody>
            </table>
              <p>*Any service not included in the Basic or Priority packages may be purchased as an add-on.</p>
          </div>
          <div className="container px-4 py-5" id="featured-3">
              <h1 className="pb-2 border-bottom">Core Business IT Services</h1>
              <p>Our core services are included in every package. We believe that every company in need of managed IT
                  services should have these services to keep their business safe, secure, and efficient. Each of our
                  customers will receive the following components.
              </p>

              <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Remote Monitoring and Maintenance</h3>
                      <p>An essential element of managed services is remote monitoring and maintenance. We install our
                        software on every computer and server at every location. In doing so, our technical team has a
                        detailed overview of the technology running at your company. This allows our team to immediately
                          handle any software or security issues that you may experience.
                      </p>

                  </div>
                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Advanced Virus Protection</h3>
                      <p>Security is very important to every company. That's why we install advanced virus protection
                        software on every computer and server that we manage. This protection will prevent any known
                          threats from ever reaching your network. Our virus protection will also notify our technical
                          team if any potential threats arise, ensuring that we can take action before your network is
                        threatened.
                      </p>

                  </div>

                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Network Protection</h3>
                      <p>We also provide our clients with extensive network protection. This software prevents potential
                          threats by blocking access to malicious websites through applications. This is an essential
                          feature to have to prevent malicious access to your network.
                      </p>

                  </div>
                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Backup Monitoring</h3>
                      <p>Backups are important to any business that uses technology. We understand this, and provide
                        every client with backup monitoring. By tracking your backups, we can ensure that they are
                          happening when they should be, and that backup sets are going to the proper location.
                      </p>
                      <p> We also offer backup setup and maintenance as an add-on service. </p>
                  </div>

                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em"></svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Office Support</h3>
                      <p>Having responsive customer support is essential to keeping your systems online and secure. Our
                          clients gain access to our 24/7 support, just a phone call away. Our technical staff are
                          very responsive, and will provide immediate assistance with any issue that you may experience.
                      </p>
                  </div>

              </div>
              <a href={"/services/"} className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Learn More</a> <a href={"/contact/main/"} className="btn btn-outline-secondary btn-lg px-4">Contact Us</a>
          </div>

          <div className="container px-4 py-5 inverted-content" id="featured-3">
              <h1 className="pb-2 border-bottom">More Information</h1>
              <p>Our packages include the essential managed services for businesses. The right package for you depends
                on the size of your business and what specific needs you have. Contact us to get more information about
                which package is right for you!
              </p>

              <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
              <div className="feature col">
                  <div
                      className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                      <svg className="bg-black" width="1em" height="1em">

                      </svg>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Basic</h3>
                  <p>Our Basic package is designed for small businesses with 1-10 computers. This package includes
                    access to our baseline software tools and to our customer support. Basic is a great choice for
                      very small businesses in need of IT maintenance and virus and network protection.
                  </p>
              </div>
              <div className="feature col">
                  <div
                      className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                      <svg className="bg-black" width="1em" height="1em">
                      </svg>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Priority</h3>
                  <p>Our Priority package is designed for companies with 10-50 computers. This package includes
                    access to our full software suite, as well as priority customer support. Priority is the right
                      choice for businesses that need IT maintenance and security for a more substantial number of
                      devices.
                  </p>
              </div>
              <div className="feature col">
                  <div
                      className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                      <svg className="bg-black" width="1em" height="1em">

                      </svg>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Pro</h3>
                  <p>
                      Our Pro package is designed for larger companies with at least 50 computers. In addition to the
                      features from the smaller packages, the Pro package includes email filtering and identity
                      protection. Pro is the optimal choice for businesses with a need for more advanced security
                      options for a larger network of devices.
                  </p>
              </div>
              </div>
              <a href={"/contact/main/"} className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Contact Us</a> <a href={"mailto:info@newverticaltech.com"} className="btn btn-outline-secondary btn-lg px-4  inverted-link-button">Email</a>
          </div>
          <div className="container px-4 py-3" id="featured-3">
              <h1 className="pb-2 border-bottom">Add-On Services</h1>
              <p>Some IT services vary greatly in time and cost, depending on the client. We offer these services at an
                  additional cost, determined on a case-by-case basis.
              </p>
              <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">
                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Security+</h3>
                      <p>Security+ includes our advanced threat detection that is applied to every computer and every email address at the organization.  This service also includes identity theft tools that keep employees from becoming engaged in fishing schemes.  These services also make sure employees do not get lured to compromised websites.</p>
                  </div>
                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">EndPoint Vault</h3>
                      <p>Endpoint Vault is a service that assists with the security of sensitive data files that employees may have access to.   These files could contain sensitive, confidential, or healthcare data.  These tools help to significantly reduce the potential that data could fall into the wrong hands.</p>


                  </div>

                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Backups</h3>
                      <p>Backups are essential to keeping your business safe and secure. Without them, you run the risk
                          of losing important information at any time. We offer setup and maintenance of on-site and
                          cloud backups. In case of any loss of data or system failure, our backups will ensure that
                          you can restore anything you lose.
                      </p>

                  </div>

                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Email Protection</h3>
                      <p>Many potential threats may enter a company's network via email. To prevent this from happening
                          to our clients, we provide email protection by keeping your email system secure, and filtering
                          suspicious emails to prevent potential threats.
                      </p>

                  </div>

                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Data and Identity Protection</h3>
                      <p>To further your protection against potential threats to your data, we provide data and identity
                          protection throughout your technology systems. We monitor all file access and configuration,
                          ensuring that secure data remains secure, and nobody gains unauthorized access to confidential
                          information.
                      </p>
                  </div>

                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">DNS Filtering</h3>
                      <p>DNS Filtering is an effective tool for preventing external threats and for ensuring that
                          company equipment is being properly used. This service allows the client to filter specific
                          website domains, preventing access to unwanted or malicious websites. DNS Filtering is very
                          important for network security, and for preventing employees from misusing your company's
                          technology.
                      </p>
                  </div>

                  <div className="feature col">
                      <div
                          className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg className="bi" width="1em" height="1em">

                          </svg>
                      </div>
                      <h3 className="fs-2 text-body-emphasis">Compliance Auditing and Remediation</h3>
                      <p>Many businesses struggle to navigate IT compliance on their own. That's why we offer compliance
                          auditing and remediation. We will test your systems against compliance controls and remediate
                          any security issues that we find.
                      </p>
                  </div>

              </div>

              <a href={"/services/"} className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Learn More</a> <a href={"/contact/main/"} className="btn btn-outline-secondary btn-lg px-4">Contact Us</a>
          </div>

           <br/>
          <div className={"inverted-content"}>
              <div className="container px-4 py-3" id="featured-3">
                  <h1 className="pb-2">Enterprise Software</h1>
              </div>
              <hr/>
              <div className="container px-4 py-3" id="featured-3">
                  <h1 className="pb-2 ">Cloud Services</h1>
                  <p>Many companies are moving to cloud-based services. When doing so, it is essential to implement
                    backups and be able to quickly recover data. New Vertical Technologies offers assistance with moving
                      programs hosted on your network to a cloud provider. We can also remove unwanted cloud services or
                      transfer services from one vendor to another.
                  </p>
              </div>

              <div className="container px-4 py-3" id="featured-3">
                  <h1 className="pb-2">Websites and Apps</h1>
                  <p>Websites are a must-have for any company in today's world. Having a clean, professional website
                      design is essential to drawing in potential clients. Your website is your tool to inform
                      customers, provide support, and even build a community around your brand. Apps can be useful for
                      the same reason, and they can also be an amazing tool for your employees.
                  </p>
                  <p>We frequently build apps and websites for our clients. In addition to developing the application or
                    website, we give our clients more insight to help them make informed decisions about the software we
                    develop for them.
                  </p>

              </div>


              <div className="container px-4 py-3" id="featured-3">
                  <h1 className="pb-2 ">Software</h1>
                  <p>In addition to our managed services, New Vertical Technologies has a strong background in software
                      development. Our team of software engineers have decades of experience in the field, building
                      complex systems for the federal government and for commercial businesses.
                  </p>
                  <p>With vast experience in data systems, control systems, and other areas of software development, the
                      New Vertical Technologies team is capable of creating an innovative new solution for your business.
                  </p>

              </div>

              <div className="container px-4 py-3" id="featured-3">
                  <h1 className="pb-2 ">Consulting</h1>
                  <p>
                      New Vertical Technologies works with a wide array of businesses, ranging from small start-ups to
                      established public companies. With our experience, we are prepared to give you expert advice while
                      we work with you.
                  </p>
                  <p>
                      In addition to developing software for you, we will assist by building roadmaps for technology
                      and software projects. We offer our clients further insight into the project, as well as
                      extensive experience in relation to software project management and cohesion.
                  </p>

              </div>
              <div className="container px-4 py-3" id="featured-3">
                  <a href={"/contact/main/"} className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Contact Us</a> <a href={"mailto:info@newverticaltech.com"} className="btn btn-outline-secondary btn-lg px-4 inverted-link-button">Email</a>
              </div>
          </div>
          </div>
<br/>

    <div className="home-valerian">
      <div className="val-left">
        <h2>Now Available: Valerian</h2>
        <h3>Simplifying Data Management</h3>
        <p>Valerian consolidates data management tasks by pulling information together from multiple locations and presenting content to users when they need it.</p>
        <a href="https://www.valeriansolutions.com?utm_source=newvertical&utm_medium=website&utm_campaign=nowavailable" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
      </div>
      <div className="val-right">
        <img src={valerian} alt="Valerian integrates systems" />
      </div>
    </div>

    <div className={"container"}>
      <div className={"cities-container"}>
        <h3>Some of the cities that we provide our technology services in:</h3>
        <ul className="cities">
          <li><Link to="/locations/alexandria/">Alexandria, VA</Link></li>
          <li><Link to="/locations/annapolis/">Annapolis, MD</Link></li>
          <li><Link to="/locations/baltimore/">Baltimore, MD</Link></li>
          <li><Link to="/locations/bowie/">Bowie, MD</Link></li>
          <li><Link to="/locations/columbia/">Columbia, MD</Link></li>
          <li><Link to="/locations/crofton/">Crofton, MD</Link></li>
          <li><Link to="/locations/crownsville/">Crownsville, MD</Link></li>
          <li><Link to="/locations/edgewater/">Edgewater, MD</Link></li>
          <li><Link to="/locations/glen-burnie/">Glen Burnie, MD</Link></li>
          <li><Link to="/locations/gambrills/">Gambrills, MD</Link></li>
          <li><Link to="/locations/hanover/">Hanover, MD</Link></li>
          <li><Link to="/locations/laurel/">Laurel, MD</Link></li>
          <li><Link to="/locations/linthicum/">Linthicum Heights, MD</Link></li>
          <li><Link to="/locations/madison/">Madison, VA</Link></li>
          <li><Link to="/locations/odenton/">Odenton, MD</Link></li>
          <li><Link to="/locations/pasadena/">Pasadena, MD</Link></li>
          <li><Link to="/locations/reston/">Reston, VA</Link></li>
          <li><Link to="/locations/severn/">Severn, MD</Link></li>
          <li><Link to="/locations/severna-park/">Severna Park, MD</Link></li>
          <li><Link to="/locations/washington-dc/">Washington D.C.</Link></li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default IndexPage
