import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageTitle from "../../components/pagetitle"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMap, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react"

import axios from 'axios'

const ContactPage = () => {
    const [state, setState] = useState({name:"",email:"",message:""})
    const [result, setResult] = useState({status:0, message:""})

    useEffect(() => {
        getKey().then()

    },[])
    const getKey = async () => {
        const result = await fetch("https://api.newvertical.tech/key")
        const data = await result.json();
        setState({...state,...data})
    }
    const handler = async () =>{
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
         let res = await fetch("https://api.newvertical.tech/submit",{
             headers: myHeaders,
             method:"POST",
             body: JSON.stringify(state)});
        setResult({...result, status:1})
    }
    return <Layout>
        <PageTitle title="Contact Us" page="PageTitleContact" />
        <Seo title="Contact Us"
             description="Contact us where we can assist in solving your data display and visualization technology challenges. We can help solve many app challenges." pathname={"/contact/main/"}/>
        <div className="content-page-wrapper">
            <div className="page-intro">
                <p >
                    New Vertical Technologies offers business and residential computer services related to solving problems and ensuring access to data.
                </p>

                <div>
                    <FontAwesomeIcon icon={faPhone} /> Sales: (410)417-8591<br />
                    <FontAwesomeIcon icon={faPhone} /> Support: (410)266-8031<br />
                    <FontAwesomeIcon icon={faMap} />
                    <div>
                        401 Log Canoe Cir<br/>
                        Stevensville, MD 21666<br/>
                        <Link style={{color:"blue"}} to="https://goo.gl/maps/jj8yPy39axuTAMCx7" target="_blank" >View Map</Link>
                    </div>

                    <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:info@NewVerticalTech.com" style={{color:"blue"}}>info@NewVerticalTech.com</a><br />
                </div>
            </div>
            <div className={"panel"}>
                {!!result.status && <div>
                    <h1>Form Submitted Successfully!  We will be in touch soon.</h1>
                </div>}
                {!result.status && <div>
                    <h2>Contact Form</h2>
                    <p>Please fill this form and press send.  Someone will be in touch with your shortly.</p>
                    <div className={"form--group"}>
                        <label className={"form--label"}>Name</label>
                        <input type="text" name="name" className={"form--input"} value={state.name} onChange={(e) => setState({...state,[e.target.name]:e.target.value}) }/>
                    </div>
                    <div>
                        <label className={"form--label"}>Email</label>
                        <input type="email" name="email" className={"form--input"} value={state.email} onChange={(e) => setState({...state,[e.target.name]:e.target.value}) }/>
                    </div>
                    <div>
                        <label className={"form--label"}>Message</label>
                        <input type="text" name="message" className={"form--input"} value={state.message} onChange={(e) => setState({...state,[e.target.name]:e.target.value}) }/>
                    </div>

                    <input type="hidden" name="_gotcha" style={{display:"none !important"}} />


                    <button className={"btn btn-primary"} type="submit" onClick={async () => await handler()}>Send</button>
                </div>}
            </div>


        </div>
    </Layout>

}

export default ContactPage
